import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";

const KampaniaGoogleAdsPage = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Kampania reklamowa w Google Ads Search",
        description:
          "Oferujemy efektywne kampanie marketingowe z naciskiem na mierzalne wyniki. Przeprowadzamy analizy, opracowujemy strategie oraz optymalizujemy kampanie reklamowe w Google Ads. Zapewniamy skuteczne kampanie internetowe dla Twojej firmy. Postaw na Google Search.  Zaufaj naszym certyfikowanym specjalistom. Skontatuj się i umów na bezpłatną konsultację. Przygotujemy dla Ciebie kamapnie reklamową w Google Search.",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Kampania <br /> <span>Google Ads Search</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#ppc">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li>
                <Link to="/audyt-kampanii-google-facebook-ads/">
                  Audyt kampanii <br /> Google/Facebook Ads
                </Link>
              </li>
              <li className="current">
                <a href="#home">Kampanie Google Ads search</a>
              </li>
              <li>
                <Link to="/kampanie-wideo-na-youtube/">
                  Kampanie Youtube Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-w-sieci-reklamowej-gdn/">Kampanie GDN</Link>
              </li>
              <li>
                <Link to="/kampanie-produktowe-product-listing-ads/">
                  Kampanie PLA
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/kampanie-linkedin-ads/">Kampanie LinkedIn</Link>
              </li>
              <li>
                <Link to="/banery-statyczne-i-dynamiczne/">
                  Banery statyczne i dynamiczne
                </Link>
              </li>
              <li>
                <Link to="/kampanie-zagraniczne/">
                  Kampanie PPC na rynki zagraniczne
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-2">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Bądź tam, <br /> gdzie Twoi klienci
                </h2>
                <p className="text-col__desc">
                  Google to bezkonkurencyjna i najpopularniejsza wyszukiwarka na
                  świecie, z której korzysta 97% polskich użytkowników. Dodajmy
                  do tego szybkie i przejrzyste efekty i nieograniczone pole do
                  optymalizacji kampanii. Właśnie to czyni Google Ads
                  największym i najbardziej skutecznym systemem reklamowym w
                  Internecie, a kampanie tekstowe podstawą większości naszych
                  kampanii.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/google-campaign-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-big-img single-offer-section-big-img--google-ads">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-big-img__title">
              Dlaczego Google <br /> Ads Search?
            </h2>
            <div className="image-container">
              <img
                className="single-offer-section-big-img__image image image--desktop"
                src={require("../assets/images/kampania-googleads-graph.png")}
                alt=""
              />
              <img
                className="single-offer-section-big-img__image image image--mobile"
                src={require("../assets/images/kampania-googleads-graph-mobile.png")}
                alt=""
              />
              <p className="image-container__text text text--1">
                Szybkie efekty
              </p>
              <p className="image-container__text text text--2">
                Natychmiastowa <br /> widoczność
              </p>
              <p className="image-container__text text text--3">
                Przejrzyste <br /> wyniki
              </p>
              <p className="image-container__text text text--4">
                Precyzyjne <br /> targetowanie
              </p>
              <p className="image-container__text text text--5">
                Nieograniczony potencjał optymalizacji
              </p>
              <p className="image-container__text text text--6">
                Przyjazny model rozliczeń
              </p>
              <ul className="big-bullets list-mobile">
                <li>Szybkie efekty</li>
                <li>Natychmiastowa widoczność</li>
                <li>Przejrzyste wyniki</li>
                <li>Precyzyjne targetowanie</li>
                <li>Nieograniczony potencjał optymalizacji</li>
                <li>Przyjazny model rozliczeń</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-timeline">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-timeline__title">
              Google Premier Partner
            </h2>
            <p className="single-offer-section-timeline__desc">
              Jesteśmy agencją z najwyższym odznaczeniem Google -{" "}
              <span>Google Premier Partner</span>. Na naszym pokładzie
              znajdziesz wyłącznie doświadczonych, certyfikowanych specjalistów
              z wieloletnim doświadczeniem w optymalizacji kampanii z wielu
              branż i niezliczoną liczbą sukcesów na koncie. <br />
              <br />
              Pracujemy w atmosferze partnerstwa i w pełnej przejrzystości -
              stworzone przez nas kampanie są Twoją własnością i nie
              zabezpieczamy się długimi okresami wypowiedzenia - wiemy, że dobre
              wyniki i relacje zapewnią nam wieloletnią współpracę. To właśnie z
              takim nastawieniem pomogliśmy już dziesiątkom firm rozwinąć ich
              skrzydła inwestycyjne rozwijając budżety marketingowe z kilku do
              kilkudziesięciu tysięcy złotych miesięcznie i/lub otwierając je na
              ekspansję na nowe, zagraniczne rynki.
            </p>
            <div className="header-container">
              <div className="custom-row">
                <div className="left-col">
                  <img
                    src={require("../assets/images/ico-arrow-timeline.svg")}
                    alt=""
                    className="header-container__arrow"
                  />
                </div>
                <div className="right-col">
                  <h3 className="header-container__title">
                    Zobacz ścieżkę naszego wspólnego sukcesu
                  </h3>
                </div>
              </div>
            </div>
            <div className="timeline-container">
              <span className="timeline-container__line"></span>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">1</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Poznajmy się</p>
                    <p className="single-item__desc">
                      Aby stworzyć dopasowaną propozycję musimy dokładnie
                      zrozumieć Twój biznes. Opowiedz nam o swoich
                      doświadczeniach, wyzwaniach i planach na kolejny miesiąc,
                      kwartał lub rok. Stwórzmy plan dostosowany do oczekiwanej
                      skali rozwoju Twojego biznesu.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">2</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">
                      Bezpłatny audyt kampanii
                    </p>
                    <p className="single-item__desc">
                      Jeżeli prowadzisz już kampanię wykonamy jej bezpłatną
                      analizę, wskazując elementy, które wymagają poprawy i
                      wyznaczając potencjał jej dalszego rozwoju. Sprawdź, co
                      możemy zrobić lepiej i więcej.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">3</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">
                      Bezpłatna strategia reklamy
                    </p>
                    <p className="single-item__desc">
                      Proponujemy Ci najnowsze rozwiązania, najbardziej aktualną
                      wiedzę oraz pakiet wielu lat naszego doświadczenia w
                      reklamie dla wielu branż. Otrzymując naszą strategię masz
                      pewność, że zaproponowane kanały i sposoby targetowania
                      reklamy przełożą się na zysk Twojego biznesu.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">4</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Start</p>
                    <p className="single-item__desc">
                      W przypadku kampani płatnych Google Ads nie musisz czekać
                      na efekty - pojawią się one już w ciągu 1 dnia jej
                      prowadzenia. Wzmożony ruch i bieżąca optymalizacja
                      przybliżą nas do realizacji Twoich celów.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">5</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Optymalizacja</p>
                    <p className="single-item__desc">
                      Nie lubimy stać biernie, a optymalizacja to nasze drugie
                      imię. Na podstawie bieżących wyników, nasza kampania
                      będzie na bieżąco modyfikowana i intensyfikowana tam,
                      gdzie przynosi Tobie największy zysk.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number number number--last">6</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Partnerstwo w sukcesie</p>
                    <p className="single-item__desc">
                      Twój rozwój, to nasz rozwój. Twój zysk, to nasz zysk.
                      #whenwewin to nasza filozofia wspólnego partnerstwa w
                      sukcesie.
                    </p>
                    <div className="inner-action">
                      <Link to="/win-win/" className="btn btn-line">
                        dowiedz się więcej
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-clients">
        <div className="row">
          <div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="section-header aligncenter">
              <span className="section-header__small-text">
                Wiemy, jak osiągnąć sukces dzięki Google Ads
              </span>
              <h2 className="section-header__title">Zaufali nam</h2>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/thailand.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/gmr.jpg")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/komputronik.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/krakow.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/techland.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/greenpoint.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/steelseries.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/imperial-stawowa.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-case-study single-offer-section-case-study--google-ads">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <h2 className="case-col__title">
              Sprawdź nasze przykładowe <br /> case studies
            </h2>
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/developer-marketing-case-1.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">branża nieruchomości</p>
              <p className="post-wrapper__title">
                <span>90 umów</span> rezerwacyjnych <span>w 90 dni</span>.
                Kampanie Google i Facebook Ads krakowskiej inwestycji
                mieszkaniowej Imperial Citi Yes
              </p>
              <p className="post-wrapper__desc">
                Nieruchomości to nasza specjalność. Sprawdź case study
                krakowskiej inwestycji mieszkaniowej Imperial CitiYes
              </p>
              <div className="text-wrapper--inner-action">
                <Link
                  to="/case-study/imperial-citiyes/"
                  className="btn btn-line"
                >
                  więcej
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/ecommerce-marketing-case-2.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">
                e-commerce - branża kosmetyczna
              </p>
              <p className="post-wrapper__title">
                Wzrost przychodów z 8813 zł do <span>389 372 zł</span>{" "}
                miesięcznie z kampanii produktowej Google Ads
              </p>
              <p className="post-wrapper__desc">
                Takie rewolucje to nasza specjalność. Sprawdź jak
                zoptymalizowaliśmy kampanię Google Ads dla sklepu e-commerce z
                perfumami i kosmetykami, zwiększając ROAS z 213% na 3358% i
                ograniczając koszt uzyskania przychodu z 47% na 3%.
              </p>
              <div className="text-wrapper--inner-action">
                <Link
                  to="/case-study/perfumeria-euforia/"
                  className="btn btn-line"
                >
                  więcej
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} messageRequired={false} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default KampaniaGoogleAdsPage;
